<template>
    <p class="field-title">{{ label }} <TooltipComponent v-if="item.description" :description="item.description" /></p>

    <div class="p-inputgroup">
        <InputText class="input-text" type="decimal" :value="modelValue" :placeholder="placeholder" @input="handleInput" />
        <!-- <span class="p-inputgroup-addon"> % </span> -->
    </div>

    <p v-if="v$.modelValue.$error" class="error-msg">
        {{ error_message }}
    </p>
</template>

<script>
import { computed, watch } from 'vue';
import InputText from 'primevue/inputtext';
import { useVuelidate } from '@vuelidate/core';
import { between, minLength, maxLength } from '@vuelidate/validators';
import TooltipComponent from './TooltipComponent.vue';

export default {
    name: 'DecimalInputComponent',
    components: {
        InputText,
        TooltipComponent,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        error_message: {
            type: String,
        },
        validation_rules: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props, { emit }) {
        const decimal = (value) => {
            return /^-?\d*\.?\d*$/.test(value) || false;
        };
        const rules = computed(() => {
            const validationRules = {};
            if (props.validation_rules.betweenValue) {
                validationRules.between = between(
                    props.validation_rules.betweenValue.min,
                    props.validation_rules.betweenValue.max
                );
            } else if (props.validation_rules.maxLength) {
                validationRules.number = decimal;
                validationRules.minLength = minLength(props.validation_rules.minLength);
                validationRules.maxLength = maxLength(props.validation_rules.maxLength);
            }
            return {
                modelValue: validationRules,
            };
        });

        const v$ = useVuelidate(rules, props);

        watch(
            () => props.modelValue,
            () => {
                v$.value.$touch();
                if (!v$.value.$error) {
                    emit('isValid', true);
                } else {
                    emit('isValid', false);
                }
            }
        );

        const handleInput = (event) => {
            emit('update:modelValue', event.target.value);

            v$.value.$touch();
            if (!v$.value.$error) {
                emit('isValid', true);
            } else {
                emit('isValid', false);
            }
        };

        return { v$, handleInput };
    },
};
</script>
