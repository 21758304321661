<template>
    <p class="field-title">{{ label }} <TooltipComponent v-if="item.description" :description="item.description" /></p>
    <Textarea
        class="input-text"
        type="text"
        :modelValue="modelValue"
        :placeholder="placeholder"
        @input="handleInput"
        rows="5"
        cols="30"
    />

    <FileUpload
        v-if="item.attachment_required"
        @saved="onSaved"
        :isImage="false"
        :isAttachment="true"
        :id="partnerId"
        :fieldId="item.id"
        :logoUrl="attachmentLink"
    />

    <p v-if="v$.modelValue.$error" class="error-msg">
        {{ error_message }}
    </p>
</template>

<script>
import { computed, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { minLength, maxLength } from '@vuelidate/validators';
import Textarea from 'primevue/textarea';
import TooltipComponent from './TooltipComponent.vue';
import FileUpload from '../common/FileUpload';

export default {
    name: 'TextAreaComponent',
    components: {
        Textarea,
        FileUpload,
        TooltipComponent,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        error_message: {
            type: String,
        },
        validation_rules: {
            type: Object,
            default: () => ({}),
        },
        attachmentLink: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const rules = computed(() => {
            if (props.validation_rules.betweenValue) {
                return {
                    modelValue: {
                        min: minLength(props.validation_rules.betweenValue.min),
                        max: maxLength(props.validation_rules.betweenValue.max),
                    },
                };
            }
            return { modelValue: {} };
        });

        const v$ = useVuelidate(rules, props);

        let partnerId = localStorage.getItem('accountId');

        watch(
            () => props.modelValue,
            () => {
                v$.value.$touch();
                if (!v$.value.$error) {
                    emit('isValid', true);
                } else {
                    emit('isValid', false);
                }
            }
        );

        const handleInput = (event) => {
            emit('update:modelValue', event.target.value);
            v$.value.$touch();
            if (!v$.value.$error) {
                emit('isValid', true);
            } else {
                emit('isValid', false);
            }
        };

        return { v$, partnerId, handleInput };
    },
};
</script>
