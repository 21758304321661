<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6" v-for="item in parentItem.data" :key="item.id">
            <ShortTextInputComponent
                v-if="item.field_type === 'short_text'"
                :item="item"
                :label="item.display_name"
                :modelValue="getAnswer(item) || modelValue[item.id]"
                :placeholder="item.placeholder"
                @update:modelValue="(value) => handleInput(item.id, value)"
                :validation_rules="item.validation_rules"
                :error_message="item.error_message"
                @isValid="handleValidation($event, item.id)"
                :attachmentLink="getAttachmentInfo(item)"
            />
            <NumberInputComponent
                v-if="item.field_type === 'number'"
                :item="item"
                :label="item.display_name"
                :modelValue="getAnswer(item) || modelValue[item.id]"
                :placeholder="item.placeholder"
                @update:modelValue="(value) => handleInput(item.id, value)"
                :validation_rules="item.validation_rules"
                :error_message="item.error_message"
                @isValid="handleValidation($event, item.id)"
            />
            <DecimalInputComponent
                v-if="item.field_type === 'decimal'"
                :item="item"
                :label="item.display_name"
                :modelValue="getAnswer(item) || modelValue[item.id]"
                :placeholder="item.placeholder"
                @update:modelValue="(value) => handleInput(item.id, value)"
                :validation_rules="item.validation_rules"
                :error_message="item.error_message"
                @isValid="handleValidation($event, item.id)"
            />
            <TextAreaComponent
                v-if="item.field_type === 'long_text'"
                :item="item"
                :placeholder="item.placeholder"
                :label="item.display_name"
                :modelValue="getAnswer(item) || modelValue[item.id]"
                @update:modelValue="(value) => handleInput(item.id, value)"
                :validation_rules="item.validation_rules"
                :error_message="item.error_message"
                :attachmentLink="getAttachmentInfo(item)"
                @isValid="handleValidation($event, item.id)"
            />
            <BooleanSelectDropdownComponent
                v-if="item.field_type === 'boolean_select'"
                :options="item.dropdown_options"
                :label="item.display_name"
                :placeholder="item.placeholder"
                :modelValue="getAnswer(item) || modelValue[item.id]"
                @update:modelValue="(value) => handleInput(item.id, value)"
                :validation_rules="item.validation_rules"
                :error_message="item.error_message"
                @isValid="handleValidation($event, item.id)"
            />
            <SingleSelectDropdownComponent
                v-if="item.field_type === 'single_select'"
                :options="item.dropdown_options"
                :label="item.display_name"
                :placeholder="item.placeholder"
                :modelValue="getAnswer(item) || modelValue[item.id]"
                @update:modelValue="(value) => handleInput(item.id, value)"
                :validation_rules="item.validation_rules"
                :error_message="item.error_message"
                @isValid="handleValidation($event, item.id)"
            />
            <SingleSelectDropdownSearchComponent
                v-if="item.field_type === 'single_select_search'"
                :options="item.dropdown_options"
                :label="item.display_name"
                :placeholder="item.placeholder"
                :modelValue="getAnswer(item) || modelValue[item.id]"
                @update:modelValue="(value) => handleInput(item.id, value)"
                :validation_rules="item.validation_rules"
                :error_message="item.error_message"
                @isValid="handleValidation($event, item.id)"
            />
            <MultiSelectDropdownComponent
                v-if="item.field_type === 'multi_select'"
                :options="item.dropdown_options"
                :label="item.display_name"
                :placeholder="item.placeholder"
                :modelValue="getAnswer(item) || modelValue[item.id]"
                @update:modelValue="(value) => handleInput(item.id, value)"
                :validation_rules="item.validation_rules"
                :error_message="item.error_message"
                @isValid="handleValidation($event, item.id)"
            />
            <MultiSelectDropdownSearchComponent
                v-if="item.field_type === 'multi_select_search'"
                :options="item.dropdown_options"
                :label="item.display_name"
                :placeholder="item.placeholder"
                :modelValue="getAnswer(item) || modelValue[item.id]"
                @update:modelValue="(value) => handleInput(item.id, value)"
                :validation_rules="item.validation_rules"
                :error_message="item.error_message"
                @isValid="handleValidation($event, item.id)"
            />
            <TreeSelectDropdownComponent
                v-if="item.field_type === 'tree_select'"
                :options="item.dropdown_options"
                :item="item"
                :label="item.display_name"
                :placeholder="item.placeholder"
                :modelValue="getAnswer(item) || modelValue[item.id]"
                @update:modelValue="(value) => handleInput(item.id, value)"
                :validation_rules="item.validation_rules"
                :error_message="item.error_message"
                @isValid="handleValidation($event, item.id)"
            />

            <FileUploadCompoent
                v-if="item.field_type === 'file_upload'"
                :label="item.display_name"
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :fieldName="item.field_name"
                :fieldId="item.id"
                :logoUrl="getAttachmentInfo(item)"
            />
        </div>
    </div>
</template>

<script>
import DecimalInputComponent from '@/components/answerBank/DecimalInputComponent';
import TextAreaComponent from '@/components/answerBank/TextAreaComponent';
import SingleSelectDropdownComponent from '@/components/answerBank/SingleSelectDropdownComponent';
import SingleSelectDropdownSearchComponent from '@/components/answerBank/SingleSelectDropdownSearchComponent';
import MultiSelectDropdownComponent from '@/components/answerBank/MultiSelectDropdownComponent';
import MultiSelectDropdownSearchComponent from '@/components/answerBank/MultiSelectDropdownSearchComponent';
import TreeSelectDropdownComponent from '@/components/answerBank/TreeSelectDropdownComponent';
import ShortTextInputComponent from '@/components/answerBank/ShortTextInputComponent';
import NumberInputComponent from '@/components/answerBank/NumberInputComponent';
import BooleanSelectDropdownComponent from '@/components/answerBank/BooleanSelectDropdownComponent';
import FileUploadCompoent from './FileUploadCompoent.vue';

export default {
    name: 'Wrapper',
    components: {
        ShortTextInputComponent,
        NumberInputComponent,
        DecimalInputComponent,
        TextAreaComponent,
        SingleSelectDropdownComponent,
        SingleSelectDropdownSearchComponent,
        MultiSelectDropdownComponent,
        MultiSelectDropdownSearchComponent,
        TreeSelectDropdownComponent,
        FileUploadCompoent,
        BooleanSelectDropdownComponent,
    },
    props: {
        parentItem: {
            type: Object,
            required: true,
        },
        modelValue: {
            type: Object,
            required: true,
        },
        answers: {
            type: Array,
            required: true,
        },
    },

    methods: {
        handleInput(fieldName, value) {
            this.$emit('update:modelValue', { [fieldName]: value });
        },

        handleValidation(isValid, fieldName) {
            this.$emit('isValid', { isValid, fieldName, categoryName: this.parentItem.category_name });
        },

        getAnswer(item) {
            for (let category of this.answers) {
                for (let field of category.data) {
                    if (field.id === item.id) {
                        return field.json_value;
                    }
                }
            }
            return null;
        },

        getAttachmentInfo(item) {
            for (let category of this.answers) {
                for (let field of category.data) {
                    if (field.id === item.id) {
                        return field.other?.attachments[0];
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss">
.p-dropdown {
    margin-left: 1rem;
    max-width: 30rem;
}
.p-multiselect {
    margin-left: 1rem;
    max-width: 30rem;
}

.p-inputgroup {
    max-width: 31rem;
    .input-text {
        margin-left: 1rem;
    }
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
.p-button.p-button-outlined {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
.p-toolbar {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
.p-button.p-button-icon-only.p-button-rounded:hover {
    background: #e4edd9 !important;
}
.p-datatable {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
.p-calendar-w-btn .p-datepicker-trigger.p-button {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.link {
    position: relative;
    left: 1.5rem;
    top: 0.5rem;
    cursor: pointer;
}

.link a {
    color: blue;
}

.attach-file {
    color: #126a70;
    margin-left: 3rem;
}

.p-treeselect {
    margin-left: 1rem !important;
    max-width: 30rem !important;
}
</style>
