<template>
    <p class="field-title">{{ label }}</p>

    <FileUpload @saved="onSaved" :isImage="false" :isAttachment="true" :id="partnerId" :fieldId="fieldId" :logoUrl="logoUrl" />
</template>

<script>
import FileUpload from '../common/FileUpload';

export default {
    name: 'FileUploadComponent',
    components: {
        FileUpload,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },

        modelValue: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        error_message: {
            type: String,
        },
        validation_rules: {
            type: Object,
            default: () => ({}),
        },
        logoUrl: {
            type: String,
        },
        fieldName: {
            type: String,
        },
        fieldId: {
            type: String,
        },
    },
    setup() {
        let partnerId = localStorage.getItem('accountId');

        return { partnerId };
    },
};
</script>
